import React from "react";
import NavigationDrawer from "../Components/NavigationDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot,
    faTruck,
    faUser,
    faReceipt,
    faChartColumn,
    faClock,
  } from "@fortawesome/free-solid-svg-icons";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import AddIcon from "@mui/icons-material/Add";
import Headers from "../Components/Headers";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { fetchWeightTracker } from "../redux_store/slice/weightTrackerSlice";
import { userData, userLoginData } from "../redux_store/slice/userInfoSlice";
import { weightTrackerData } from "../redux_store/slice/weightTrackerSlice";
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import axios from "axios";

const Dashboard = () => {
    const columns = [
        {
          field: "slipno",
          headerName: "Slip No.",
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "vehicleno",
          headerName: "Vehicle No.",
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "weightdate",
          headerName: "Weight Date",
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "netweight",
          headerName: "Net Weight",
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "receipt",
          headerName: "Receipt",
          flex: 1,
          headerAlign: "center",
          align: "center",
          renderCell: (cellValues) => {
             if(cellValues.row.receipt){
                return (
                  <a
                    href={cellValues.row.receipt}
                    target="_blank"
                    download="reciept.pdf"
                  >
                    <ReceiptLongOutlinedIcon style={{ color: "#6759FF", fontSize: '20px' }} />
                  </a>
                );
              }else{
                return(
                  <button>
                      <ManageHistoryIcon style={{ color: "#6759FF",fontSize: '20px',color: '#E91E63' }} />
                  </button>
                )
              } 
          },
        },
      ];
      const [vehicleCount, setVehicleCount] = React.useState(0);
      const [zoneCount, setZoneCount] = React.useState(0);
      const [monthNetWeight, setMonthNetWeight] = React.useState(0);
      const [receiptCount, setReceiptCount] = React.useState(0);
      const [netWeight, setNetWeight] = React.useState(0);

      const formatDateToDDMMYYYY = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
      };

      const dispatch = useDispatch();
      const user = useSelector(userData);
      const storedUserData = JSON.parse(localStorage.getItem("userIdData"));

      const weightData = useSelector(weightTrackerData) || [];

      React.useEffect(() => {
        //if (storedUserData && storedUserData?.data?.userData?.userType) {
          dispatch(
            fetchWeightTracker({
              zoneId: storedUserData?.data?.userData?.zone?.zoneId,
            })
          );

          axios.get("https://weighingbridge.vrajpath.com/vehicles/count").then((resp)=>{
            setVehicleCount(resp.data.count);
          });
          axios.get("https://weighingbridge.vrajpath.com/zones/count").then((resp)=>{
            setZoneCount(resp.data.count);
          });

          let date = new Date();
          date.setHours(0);
          date.setMinutes(0);

          let filter = {
                    where: {
                        "createdAt": { "gte" : date.toISOString() }
                    }
                };

          if(storedUserData && storedUserData?.data?.userData?.userType === 'Employee'){
            filter.where.userId=storedUserData?.data?.userData?.userId;
          }

          axios.get("https://weighingbridge.vrajpath.com/weighing-data", {
            params: {
                filter: JSON.stringify(filter)
            }
          }).then((resp)=>{
            setReceiptCount(resp.data.length);
            let total = 0;
            resp.data.forEach((data)=>{
                total += data.netWeight;
            })
            setNetWeight(total);
          });

          let newDate = new Date();
          newDate.setHours(0);
          newDate.setMinutes(0);
          newDate.setDate(1);

          let newfilter = {
                    where: {
                        "createdAt": { "gte" : newDate.toISOString() }
                    }
                };

          if(storedUserData && storedUserData?.data?.userData?.userType === 'Employee'){
            newfilter.where.userId=storedUserData?.data?.userData?.userId;
          }

          axios.get("https://weighingbridge.vrajpath.com/weighing-data", {
            params: {
                filter: JSON.stringify(newfilter)
            }
          }).then((resp)=>{
            let total = 0;
            resp.data.forEach((data)=>{
                total += data.netWeight;
            })
            setMonthNetWeight(total);
          });
        //console.log("local storage", storedUserData);
        // }
      }, []);

      const weightMappedData = weightData.map((item) => ({
        id: item.weighingDataId,
        weighttype: item.weightType,
        vehicleno: item.vehicleNumber,
        slipno: item.slipNumber,
        weightdate: formatDateToDDMMYYYY(new Date(item.createdAt)),
        vehicletype: item.vehicleType,
        supplier: item.supplier,
        charges: item.charges,
        netweight: item.netWeight,
        measuretype: item.measureType,
        receipt: item.receiptUrl,
      }));

  return (
        <div className="flex flex-row">
            <NavigationDrawer />
            <div className="bg-[#FAFAFA] w-full h-full flex flex-col min-h-screen">
                <Headers
                header="Dashboard"
                showAddButton={true}
                addWhat="Manage Users"
                addPage="/UserTracker"
                />
                 <div className="w-full">
                    <div className="grid grid-cols-5 grid-rows-1 pl-12 pr-6">
                        <div className="ml-2 shadow-md h-40 w-4/5 rounded-md" style={{borderBottom: '5px solid #6759ff',background: '#dcdcfd63'}}>
                            <h1 className="mt-14 text-center text-sm mb-2 font-bold" style={{color:'grey'}}> <FontAwesomeIcon icon={faTruck} />   Total Vehicles</h1>
                            <p className="text-center text-2xl font-extrabold text-purple">{vehicleCount}</p>
                        </div>
                        <div className="ml-2 shadow-md h-40 w-4/5 rounded-md" style={{borderBottom: '5px solid #6759ff',background: '#dcdcfd63'}}>
                            <h1 className="mt-14 text-center text-sm mb-2 font-bold" style={{color:'grey'}}><FontAwesomeIcon icon={faLocationDot} />   Total Zones</h1>
                            <p className="text-center text-2xl font-extrabold text-purple">{zoneCount}</p>
                        </div>
                        <div className="ml-2 shadow-md h-40 w-4/5 rounded-md" style={{borderBottom: '5px solid #6759ff',background: '#dcdcfd63'}}>
                            <h1 className="mt-14 text-center text-sm mb-2 font-bold" style={{color:'grey'}}><FontAwesomeIcon icon={faReceipt} />   Today's Receipts</h1>
                            <p className="text-center text-2xl font-extrabold text-purple">{receiptCount}</p>
                        </div>
                        <div className="ml-2 shadow-md h-40 w-4/5 rounded-md" style={{borderBottom: '5px solid #6759ff',background: '#dcdcfd63'}}>
                            <h1 className="mt-14 text-center text-sm mb-2 font-bold" style={{color:'grey'}}><FontAwesomeIcon icon={faReceipt} />   Today's Net Weight</h1>
                            <p className="text-center text-2xl font-extrabold text-purple">{netWeight}</p>
                        </div>
                        <div className="ml-2 shadow-md h-40 w-4/5 rounded-md" style={{borderBottom: '5px solid #6759ff',background: '#dcdcfd63'}}>
                            <h1 className="mt-14 text-center text-sm mb-2 font-bold" style={{color:'grey'}}><FontAwesomeIcon icon={faReceipt} />   Net Weight This Month</h1>
                            <p className="text-center text-2xl font-extrabold text-purple">{monthNetWeight}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 grid-rows-1 mt-12 pl-12 pr-2">
                        <div className="shadow-md h-96 w-11/12 rounded-md bg-white">
                            <h1 className="mt-10 text-left text-sm mb-2 font-bold ml-12" style={{color:'grey'}}> <FontAwesomeIcon icon={faChartColumn} />   Actitivies</h1>
                            <div className="grid grid-cols-1 grid-rows-10 mt-10" style={{overflowX: 'hidden',
                                    overflowY: 'scroll',
                                    height: '220px'}}>
                                {
                                    weightData.map((data, index)=>{
                                        return <>
                                            <div key={`${data.slipNumber}${index}`} className="ml-12 h-20 w-10/12 rounded-md">
                                                <div style={{
                                                    background: '#e7e6f9',
                                                    height: '50px',
                                                    width: '50px',
                                                    borderRadius: '25px'}}>
                                                    <FontAwesomeIcon icon={faReceipt} style={{
                                                            fontSize: '22px',
                                                            color: '#6759ff',
                                                            marginTop: '14px',
                                                            marginLeft: '17px',
                                                    }}/>
                                                </div>
                                                <p className="mt-2 text-left text-sm mb-2 pl-20" style={{marginTop: '-48px'}}><label className="font-bold">{data.user?.fullName}({data.user?.employeeId})</label> has added receipt <label className="font-bold"> {data.slipNumber}</label> for vehicle  <label className="font-bold">{data.vehicleNumber}</label> with RFID <label className="font-bold">{data.rfidNumber}</label> on {formatDateToDDMMYYYY(new Date(data.createdAt))} at <label className="font-bold">{data.zone?.name}</label>.</p>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                        <div className="shadow-md h-96 w-11/12 rounded-md bg-white">
                            <h1 className="mt-10 text-left text-sm mb-2 font-bold ml-8 mb-5" style={{color:'grey'}}><FontAwesomeIcon icon={faClock} />   Recent Receipts</h1>
                            <DataGrid
                                rows={weightMappedData}
                                rowHeight={50}
                                columns={columns}
                                hideFooter={true}
                                sx={{
                                "&, [class^=MuiDataGrid]": { border: "none" },

                                "& .MuiDataGrid-columnHeaders": {
                                    borderBottom: "none",
                                },

                                "& .MuiDataGrid-columnHeaderTitle": {
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    color:'grey'
                                },

                                "& .MuiDataGrid-virtualScroller": {
                                    overflowX: 'hidden',
                                    overflowY: 'scroll',
                                    height: '200px'
                                },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Dashboard;