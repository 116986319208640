import React from "react";
import truck from "../assets/vehicleinformationtruck.svg";
import ReusableForm from "../Components/ReusableForm";
import { useForm } from "react-hook-form";
import NavigationDrawer from "../Components/NavigationDrawer";
import { vehicleEditData } from "../redux_store/slice/vehicleTrackerSlice";
import axios from "axios";
import Headers from "../Components/Headers";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const VehicleInformation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const EditData = useSelector(vehicleEditData);
 //console.log(EditData);

  let formInfo = {};

  const onChange = (field, e) =>{
      formInfo[field] = e;
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newFormDataObject = {
      ...EditData,
      ...formInfo
    };

    delete newFormDataObject.createdAt;
    delete newFormDataObject.vehicleId;

    try {
      const res = await axios.patch(
        `https://weighingbridge.vrajpath.com/vehicles/${EditData?.vehicleId}`,
        JSON.stringify(newFormDataObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Vehicle is Updated!");
      const form = event.target;
      form.reset();
      //console.log(res.data);
      return res.data;
    } catch (error) {
      toast.error("There is some error encountered. Please contact the system administrator.");
      //console.log(error);
    }
  };

  const fields = EditData && [
    {
      name: "rfidNumber",
      label: "RFID",
      type: "number",
      required: true,
      maxLength: 20,
      defaultValue: EditData?.rfidNumber,
      onChange: (e) => onChange('rfidNumber', e.target.value),
    },
    {
      name: "vehicleNumber",
      label: "Vehicle Number",
      type: "string",
      required: true,
      maxLength: 20,
      defaultValue: EditData?.vehicleNumber,
      onChange: (e) => onChange('vehicleNumber', e.target.value),
    },
    {
      name: "vehicleType",
      label: "Vehicle Type",
      type: "select",
      required: true,
      options: ["LMV", "HMV"],
      defaultValue: EditData?.vehicleType,
      onChange: (e) => onChange('vehicleType', e.target.value),
    },
    {
      name: "tareWeight",
      label: "Tare Weight",
      type: "number",
      required: true,
      maxLength: 20,
      defaultValue: EditData?.tareWeight,
      onChange: (e) => onChange('tareWeight', e.target.value),
      readOnly: true,
    },
  ];

  const customInputClass = "mx-2 mb-7";
  const customSelectClass = "mx-2 mb-7";
  const customLabelClass = "mx-2 ";
  const customButtonClass = "px-18 my-5";
  const customClass = "";
  return (
    <div className="flex flex-row">
      <NavigationDrawer />
      <div className="bg-[#FAFAFA] w-full min-h-screen flex flex-col">
        <Headers
          header="Vehicle Information"
          showBackButton={true}
          Linkbackto="/VehicleTracker"
        />

        <main className="flex flex-row justify-normal justify-center items-center">
          <img src={truck} alt="truck" style={{'marginLeft': '-300px'}} className="-ml-70 h-auto w-auto" />
          <ReusableForm
            onSubmit={onSubmit}
            fields={fields}
            errors={errors}
            showCancel={true}
            cancelLink={'/VehicleTracker'}
            submitButtonLabel={"Submit"}
            customInputClass={customInputClass}
            customLabelClass={customLabelClass}
            customButtonClass={customButtonClass}
            customSelectClass={customSelectClass}
            customClass={customClass}
          />
        </main>
      </div>
    </div>
  );
};

export default VehicleInformation;
