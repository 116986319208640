import React, { useState } from "react";
import NavigationDrawer from "../Components/NavigationDrawer";
import ReportTable from "../Components/ReportTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import RequestReportModal from "./RequestReportModal"; 
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const Report = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRequestSubmit = () => {
    // Handle the report request submission logic here
    // You can send the request to the server or perform other actions
    // Close the modal after submitting
    closeModal();
  };

  return (
    <div className="flex flex-row">
      <NavigationDrawer />
      <div className="bg-[#FAFAFA] w-full h-full flex flex-col min-h-screen">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <h2 className="lg:text-[2rem] md:text-[1.75rem] xl:text-[2.25rem] 2xl:text-[2.5rem] 3xl:text-[3rem] p-7 ml-10">Report</h2>
          </div>
          <button
            className="items-end m-8 rounded-lg px-8 py-2 text-white bg-[#6759FF] hover:bg-[#5549CC] text-sm mr-20"
            onClick={openModal}
            style={{lineHeight: 1.4}}
          >
            <AddIcon className="mr-2" style={{fontSize: '20px'}}/>
            Request Report
          </button>
        </div>
        <ReportTable />
      </div>

      <RequestReportModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onRequestSubmit={handleRequestSubmit}
      />
    </div>
  );
};

export default Report;
