import * as React from "react";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { VehicleTrackerData } from "../redux_store/slice/vehicleTrackerSlice";
import { fetchVehicleTracker, vehicleGetEditData } from "../redux_store/slice/vehicleTrackerSlice";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useDispatch, useSelector } from "react-redux";
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import VehicleHistoryModal from "../Pages/VehicleHistoryModal"; 
import { Link } from "react-router-dom";


const VehicleTable = () => {
  const [vehicleNumber, setVehicleNumber] = useState('');
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchVehicleTracker());
  },[]);

  const vehicleTracker = useSelector(VehicleTrackerData) || [];

  const formatDateToDDMMYYYY = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const mappedData = vehicleTracker.map((item) => ({
    id: item.vehicleId,
    vehicleId: item.vehicleId,
    rfidNumber: item.rfidNumber,
    vehicleNumber: item.vehicleNumber,
    vehicleType: item.vehicleType,
    tareWeight: item.tareWeight,
    createdAt: formatDateToDDMMYYYY(new Date(item.createdAt)),
    editData: item
  }));


  const columns = [
    // {
    //   field: "vehicleId",
    //   headerName: "Vehicle ID",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "left",
    //   type:"number",
    //   maxLength:"20",
    // },
    {
      field: "rfidNumber",
      headerName: "RFID Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type:"number",
      maxLength:"20",
    },
    {
      field: "vehicleNumber",
      headerName: "Vehicle Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type:"text",
      maxLength:"20",
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type:"text",
      maxLength:"20",
    },
    {
      field: "tareWeight",
      headerName: "Tare Weight",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type:"number",
      maxLength:"20",
    },
    {
      field: "createdAt",
      headerName: "Added On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type:"number",
      maxLength:"20",
    },
    {
      field: "receipt",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => {
         const handleEditClick = () => {
         //console.log(cellValues);
          dispatch(vehicleGetEditData(cellValues?.row.editData));
        };

        return (
          <div>
           <button onClick={handleEditClick}>
            <Link to="/EditableVehicleInformation">
              <BorderColorOutlinedIcon style={{ color: "#6759FF",fontSize: '20px' }} />
            </Link>
          </button>
          <button className="ml-4" onClick={()=> openModal(cellValues.row.vehicleNumber)}>
            <WorkHistoryOutlinedIcon style={{ color: "#6759FF", fontSize: '20px' }} />
          </button>
          </div>
        );
      },
    },
 ];

 const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (vehicleNumber) => {
    setIsModalOpen(true);
    setVehicleNumber(vehicleNumber);
   //console.log(vehicleNumber);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ height: "100%" }} className="mx-20 inter-font">
      <DataGrid
        rows={mappedData}
        rowHeight={80}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          "&, [class^=MuiDataGrid]": { border: "none" },

          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #967BB6",
          },

          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 500,
            fontSize: '15px',
          },
        }}
      />
      {vehicleNumber && <VehicleHistoryModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        vehicleNumber={vehicleNumber}
      />}
    </div>
    
  );
};

export default VehicleTable;
