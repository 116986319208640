import React from "react";
import NavigationDawer from "../Components/NavigationDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import glass from "../assets/vehicleinformationglass.svg";
import ReusableForm from "../Components/ReusableForm";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userEditData } from "../redux_store/slice/userTrackerSlice";
import axios from "axios";
import Headers from "../Components/Headers";
import { toast } from "react-hot-toast";

const EditableUserInformation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const EditData = useSelector(userEditData);
 //console.log(EditData);

  let formInfo = {};

  const onChange = (field, e) =>{
    if(['dateOfBirth','joiningDate'].includes(field)){
      formInfo[field] = `${e}T00:00:00.000Z`;
    }else{
      formInfo[field] = e;
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newFormDataObject = {
      ...EditData,
      ...formInfo
    };

    delete newFormDataObject.createdAt;
    delete newFormDataObject.userId;
    delete newFormDataObject.zone;

   //console.log(newFormDataObject);

    try {
      const res = await axios.patch(
        `https://weighingbridge.vrajpath.com/users/${EditData?.userId}`,
        JSON.stringify(newFormDataObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //console.log(res.status);
      toast.success("User is Updated!");
      return res;
    } catch (error) {
      //console.log(error);
      toast.error("There is some error encountered. Please contact the system administrator.");
    }
  };

  const fields = EditData && [
    {
      name: "username",
      label: "Username",
      type: "text",
      required: true,
      maxLength: 20,
      defaultValue: EditData?.username,
      onChange: (e) => onChange('username', e.target.value),
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      required: false,
      maxLength: 50,
      onChange: (e) => onChange('password', e.target.value),
    },
    {
      name: "fullName",
      label: "Name",
      type: "text",
      required: true,
      maxLength: 20,
      defaultValue: EditData?.fullName,
      onChange: (e) => onChange('fullName', e.target.value),
    },
    {
      name: "employeeId",
      label: "Employee ID",
      type: "text",
      required: true,
      maxLength: 200,
      defaultValue: EditData?.employeeId,
      onChange: (e) => onChange('employeeId', e.target.value),
    },
    {
      name: "dateOfBirth",
      label: "Date Of Birth",
      type: "date",
      required: true,
      maxLength: 200,
      defaultValue: EditData?.dateOfBirth?.split('T')[0],
      onChange: (e) => onChange('dateOfBirth', e.target.value),
    },
    {
      name: "joiningDate",
      label: "Joining Date",
      type: "date",
      required: true,
      maxLength: 200,
      defaultValue: EditData?.joiningDate?.split('T')[0],
      onChange: (e) => onChange('joiningDate', e.target.value),
    },
    {
      name: "userType",
      label: "User Type",
      type: "select",
      required: true,
      options: ["Admin", "Employee"],
      defaultValue: EditData?.userType,
      onChange: (e) => onChange('userType', e.target.value),
    },
  ];

  return (
    <div className="flex flex-row">
      <NavigationDawer />
      <div className="bg-[#FAFAFA] w-full h-full flex flex-col min-h-screen">
        <Headers
          header="User Information"
          showBackButton={true}
          Linkbackto="/UserTracker"
        />
        <main className="flex flex-row justify-center items-center">
          <img src={glass} alt={"magnifying glass"} className="h-auto w-auto" />
          <ReusableForm
            onSubmit={onSubmit}
            fields={fields}
            errors={errors}
            showCancel={true}
            submitButtonLabel={"Confirm"}
            cancelLink={"/UserTracker"}
          />
        </main>
      </div>
    </div>
  );
};

export default EditableUserInformation;
