import avatar from "../assets/Avatar.png";
import React from "react";
import Button from "./Button";
import {
  faLocationDot,
  faTruck,
  faChartPie,
  faArrowRightFromBracket,
  faUser,
  faReceipt,
  faHouse,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { userData } from "../redux_store/slice/userInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setLoginStatus } from "../redux_store/slice/userInfoSlice";
import { useNavigate } from "react-router-dom";


const NavigationDrawer = () => {
  const navigate = useNavigate();
  const user = useSelector(userData);
  const dispatch = useDispatch();
  //localStorage.setItem("userIdData", JSON.stringify(user));
  const storedUserData = JSON.parse(localStorage.getItem("userIdData"));

  React.useEffect(() => {
    if(!storedUserData){
      navigate('/')
    }
  }, []);

  const handleLogOut = async () => {
    try {
      dispatch(setLoginStatus(false));
      //console.log(JSON.stringify({ userId: storedUserData?.data?.userData?.userId}));
      const res = await axios.post(
        "https://weighingbridge.vrajpath.com/users/logout",
        JSON.stringify({ userId: storedUserData?.data?.userData?.userId }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.clear();
      navigate('/');

      return res.data;
    } catch (error) {
     //console.log(error);
    }
  };

  return (
    <div className="w-[21%] 3xl:w-[15%] min-h-full flex flex-col flex-shrink -mt-6 p-6 z-10 bg-white shadow-xl">
      <div className="flex  flex-col lg:flex-row h-1/5 items-center" style={{flexDirection: 'row'}}>
        <span className="flex flex-col">
          <img
            src={avatar}
            alt="avatar"
            className="w-20 h-20 rounded-full m-3 ml-1"
          />
        </span>
        <span className="flex flex-col">
          <h4>{storedUserData?.data?.userData?.fullName}</h4>
          <p style={{textAlign: 'left',letterSpacing:'1.2px'}}className="text-xs lg:text-md xl:text-xl 2xl:text-[1.7rem] 3xl:text-[2rem]">{storedUserData?.data?.userData?.employeeId}</p>
        </span>
      </div>

      <div className="w-full flex flex-col items-start xl:text-2xl 2xl:text-[1.7rem] 3xl:text-[2rem]">
      {<Button to="/Dashboard" icon={faHouse} pathName='/Dashboard'>
          Dashboard
        </Button>}
        {storedUserData?.data?.userData?.userType!=='Employee' && <Button to="/ZoneTracker" icon={faLocationDot} pathName='/ZoneTracker'>
          Zone Tracker
        </Button>}

        <Button to="/VehicleTracker" icon={faTruck} pathName='/VehicleTracker'>
          Vehicle Tracker
        </Button>
        <Button to="/WeighingTracker" icon={faReceipt} pathName='/WeighingTracker'>
          Weighing Tracker
        </Button>
        {storedUserData?.data?.userData?.userType!=='Employee' && <Button to="/UserTracker" icon={faUser} pathName='/UserTracker'>
          User Tracker
        </Button>}

        {storedUserData?.data?.userData?.userType!=='ABC' && <Button to="/Report" icon={faChartPie} pathName='/Report'>
          Reports
        </Button>}

        {storedUserData?.data?.userData?.userType!=='ABC' && <Button to="/UserManual" icon={faBook} pathName='/UserManual'>
          User Manual
        </Button>}

        <Button
          icon={faArrowRightFromBracket}
          className="text-red-600"
          onClick={handleLogOut}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
};

export default NavigationDrawer;
