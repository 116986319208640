import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from 'react-router-dom';

const Button = ({ to, icon, children, onClick, className, pathName }) => {
  const location = useLocation();
  const [isSelected, setIsSelected] = useState(false);

  const handleButtonClick = () => {
    setIsSelected(true);
    if(onClick)
      onClick();
  };

  const defaultClasses = `p-3 w-full mr-5 flex items-left flex-row mb-4 rounded-lg w-full hover:bg-[#6759FF] hover:text-white mb-5 ${
    isSelected ? "bg-[#6759FF] text-white" : "text-black bg-transparent"
  }`;

  const buttonClasses = `${defaultClasses} ${className}`;

  return (
    <Link to={to} className="w-full">
    <button className={buttonClasses} onClick={handleButtonClick} style={(pathName=== location.pathname)? {color:"white", backgroundColor:"#6759ff",lineHeight: 1, padding:'18px 15px'}:{lineHeight: 1, padding:'18px 15px'}}>
      <FontAwesomeIcon icon={icon} />
      <div className={`ml-4`}>
       {children}
      </div>
    </button>
    </Link>
  );
};

export default Button;
