import React from "react";
import ReusableForm from "../Components/ReusableForm";
import informationTruck from "../assets/zone_information_truck.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import NavigationDrawer from "../Components/NavigationDrawer";
import axios from "axios";
import { useSelector } from "react-redux";
import { zoneFormGeneralEditData } from "../redux_store/slice/zoneTrackerSlice";
import { userTrackerData } from "../redux_store/slice/userTrackerSlice";
import { toast } from "react-hot-toast";

const ZoneEditInformationAdvance = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const zoneFormData = useSelector(zoneFormGeneralEditData);
  let formInfo = {...zoneFormData};
  delete formInfo.newEditData;

 //console.log(zoneFormData);

  const onChange = (field, e) =>{
    formInfo[field] = e;
   //console.log(field, e);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const formDataObject = {};
    formData.forEach((value, key) => {
      if (key === "baudRate" || key === "dataBits" || key === "stopBits") {
        formDataObject[key] = parseFloat(value);
      } else if (key === "flowControl") {
        formDataObject[key] = value === "true" ? true : false;
      }else {
        formDataObject[key] = value;
      }
    });

    const mergedData = {
      ...zoneFormData,
      ...formDataObject,
    };

    delete mergedData.newEditData;

   //console.log(mergedData);

    try {
      const res = await axios.patch(
        `https://weighingbridge.vrajpath.com/zones/${zoneFormData.newEditData.zoneId}`,
        JSON.stringify(mergedData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const form = event.target;
      form.reset();
      toast.success("Zone is Updated!");
      return res.data;
    } catch (error) {
      toast.error("There is some error encountered. Please contact the system administrator.");
      //console.log(error);
    }

    //console.log(formDataObject);
    
  };

  const fields = [
    {
      name: "cctvIPAddress",
      label: "CCTV IP Address",
      type: "text",
      required: true,
      defaultValue:zoneFormData?.newEditData?.cctvIPAddress,
      onChange: (e) => onChange('cctvIPAddress',e.target.value),
    },
    {
      name: "rfidPort",
      label: "RFID Port",
      type: "select",
      required: true,
      options: ["COM1","COM2","COM3","COM4","COM5"],
      defaultValue:zoneFormData?.newEditData?.rfidPort,
      onChange: (e) => onChange('rfidPort',e.target.value),
    },
    {
      name: "weighingPort",
      label: "Weighing Port",
      type: "select",
      required: true,
      options: ["COM1","COM2","COM3","COM4","COM5"],
      defaultValue:zoneFormData?.newEditData?.weighingPort,
      onChange: (e) => onChange('weighingPort',e.target.value),
    },
    {
      name: "baudRate",
      label: "Baud Rate",
      type: "number",
      maxLength: 20,
      required: true,
      defaultValue:zoneFormData?.newEditData?.baudRate,
      onChange: (e) => onChange('baudRate',e.target.value),
    },
    {
      name: "parity",
      label: "Parity",
      type: "text",
      required: true,
      maxLength: 20,
      defaultValue:zoneFormData?.newEditData?.parity,
      onChange: (e) => onChange('parity',e.target.value),
    },
    {
      name: "dataBits",
      label: "Data Bits",
      type: "number",
      required: true,
      maxLength: 20,
      defaultValue:zoneFormData?.newEditData?.dataBits,
      onChange: (e) => onChange('dataBits',e.target.value),
    },
    {
      name: "stopBits",
      label: "Stop Bits",
      type: "number",
      required: true,
      maxLength: 20,
      defaultValue:zoneFormData?.newEditData?.stopBits,
      onChange: (e) => onChange('stopBits',e.target.value),
    },
    {
      name: "flowControl",
      label: "Flow Control",
      type: "boolean",
      required: true,
      maxLength: 20,
      defaultValue:zoneFormData?.newEditData?.flowControl,
      onChange: (e) => onChange('flowControl',e.target.value),
    },
  ];

  const customInputClass = "w-96";
  const customButtonClass = "m-3";
  const customClass = "mt-10";
  return (
    <main className="flex flex-row items-center justify-center">
      <img src={informationTruck} alt={"truck"} className="h-auto w-auto"/>
      <ReusableForm
        onSubmit={onSubmit}
        fields={fields}
        errors={errors}
        showCancel={true}
        submitButtonLabel={"Submit"}
        customInputClass={customInputClass}
        customButtonClass={customButtonClass}
        customClass={customClass}
        cancelLink={'/ZoneTracker'}
        onChange = {props.changeTabprop}
      />
    </main>
  );
};

export default ZoneEditInformationAdvance;
