import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchRecieptUrl = createAsyncThunk(
  "recieptUrl/fetchRecieptUrl",
  async ({ fromDate, toDate, VehicleType }) => {
    try {
      const userData = JSON.parse(localStorage.getItem('userIdData'));
      const query = {
        and: [
          { createdAt: { gte: fromDate } },
          { createdAt: { lte: toDate } },
          { vehicleType: VehicleType },
        ],
      };

      if(userData.data.userData.userType === 'Employee'){
        query.and.push({userId : userData.data.userData.userId});
      }

      const res = await axios.get("https://weighingbridge.vrajpath.com/weighing-data", {
        params: {
          filter: JSON.stringify({
            offset: 0,
            // limit: 100,
            skip: 0,
            order: "createdAt desc",
            where: query
          }),
        },
      });
      return res.data;
    } catch (error) {
      //console.log(error);
    }
  }
);

const initialState = {
    loading: false,
    recieptUrlData: [],
    error: null,
};

export const recieptUrlSlice = createSlice({
    name: "recieptUrl",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRecieptUrl.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchRecieptUrl.fulfilled, (state, action) => {
            state.loading = false;
            state.recieptUrlData = action.payload;
        });
        builder.addCase(fetchRecieptUrl.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
});

export default recieptUrlSlice.reducer;
export const recieptUrlData = (state) => state.recieptUrl.recieptUrlData;
