import React from "react";
import Headers from "../Components/Headers";
import NavigationDrawer from "../Components/NavigationDrawer";

const UserManual = () => {
  return (
    <div className="flex flex-row" style={{height:'100%'}}>
      <NavigationDrawer />
      <div className="bg-[#FAFAFA] w-full h-full flex flex-col min-h-scren">
        <Headers
          header="User Manual"
          showAddButton={false}
          addWhat="Add User"
          addPage="/UserInformation"
          showBackButton={false}
          Linkbackto="/WeighingTracker"
        />
        <div className="mx-20"  style={{height:'85%'}}>
          <iframe src="https://weighing-product-resources.s3.ap-south-1.amazonaws.com/user-manual/user-manual.pdf" height="100%" width="100%"></iframe>
        </div>
      </div>
    </div>
  );
};

export default UserManual;
