import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
    faReceipt,
  } from "@fortawesome/free-solid-svg-icons";

const VehicleHistoryModal = ({ isOpen, onRequestClose, vehicleNumber }) => {
    const [weightData, setWeightData] = React.useState([]);

    const formatDateToDDMMYYYY = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
      };

      const onCloseInit = ()=>{
        setWeightData([]);
        onRequestClose();
      }

      if(isOpen && !weightData.length){
        const response = axios.get(
            "https://weighingbridge.vrajpath.com/weighing-data",{
            params: {
                filter: JSON.stringify({
                  offset: 0,
                  // limit: 100,
                  skip: 0,
                  order: "createdAt desc",
                  where: {
                    vehicleNumber:vehicleNumber
                  },
                  include: [
                    {
                      relation: "user",
                      scope: {
                        offset: 0,
                        skip: 0,
                        order: "createdAt desc",
                        fields: {},
                        include: [],
                      },
                    },
                    {
                      relation: "zone",
                      scope: {
                        offset: 0,
                        skip: 0,
                        order: "createdAt desc",
                        fields: {},
                        include: [],
                      },
                    },
                  ],
                }),
                }
              }
          ).then((response)=>{
            setWeightData(response.data);
          });     
      }

 //console.log(weightData);

  return (isOpen) ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50">
      <div className="relative w-lg mx-auto" style={{marginRight: 0,height: '100%'}}>
        <div className="modal bg-white shadow-md rounded-lg w-[30rem] h-full">
          <div className="modal-content p-4">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={onCloseInit}
            >
              <CloseIcon />
            </button>
            <h1 className="text-h1 p-3 mb-4" style={{fontSize: '1.2rem'}}>Vehicle History - <label className="font-bold">{vehicleNumber}</label></h1>
            <div className="grid grid-cols-1 grid-rows-10 mt-10">
                                {
                                    weightData.map((data, index)=>{
                                        return <>
                                            <div key={`${data.slipNumber}${index}`} className="ml-12 h-20 w-10/12 rounded-md mb-10">
                                                <div style={{
                                                    background: '#e7e6f9',
                                                    height: '50px',
                                                    width: '50px',
                                                    borderRadius: '25px'}}>
                                                    <FontAwesomeIcon icon={faReceipt} style={{
                                                            fontSize: '22px',
                                                            color: '#6759ff',
                                                            marginTop: '14px',
                                                            marginLeft: '17px',
                                                    }}/>
                                                </div>
                                                <p className="mt-2 text-left text-sm mb-2 pl-20" style={{marginTop: '-48px'}}><label className="font-bold">{data.user?.fullName}({data.user?.employeeId})</label> has added receipt <label className="font-bold"> {data.slipNumber}</label> for vehicle  <label className="font-bold">{data.vehicleNumber}</label> with RFID <label className="font-bold">{data.rfidNumber}</label> on {formatDateToDDMMYYYY(new Date(data.createdAt))} at <label className="font-bold">{data.zone?.name}</label>.</p>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default VehicleHistoryModal;
