import React from "react";
import ReusableForm from "../Components/ReusableForm";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import axios from "axios";
import { fetchRecieptUrl } from "../redux_store/slice/recieptUrlSlice";
import { logInUser } from "../redux_store/slice/userInfoSlice";
import { userLoginData } from "../redux_store/slice/userInfoSlice";
import { userData } from "../redux_store/slice/userInfoSlice";
import { recieptUrlData } from "../redux_store/slice/recieptUrlSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { json2csv } from 'json-2-csv';
import { io } from "socket.io-client";

const RequestReportModal = ({ isOpen, onRequestClose, onRequestSubmit, weighingData }) => {
  let updatedWeighingData = Object.assign({}, weighingData);
  //updatedWeighingData.tareWeight = 1300;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const customInputClass = "w-full min-w-full my-4";
  const customSelectClass = "w-full min-w-full my-4";
  const customClass = "m-4";
  const customButtonClass = "flex ml-64 rounded-md";

  const [formDataObject, setFormDataObject] = React.useState({});
  const [tareWeight, setTareWeight] = React.useState(updatedWeighingData.tareWeight);

  const storedUserData = JSON.parse(localStorage.getItem("userIdData"));

  const dispatch = useDispatch();

   React.useEffect(() => {
      if(!weighingData.tareWeight){
        const socket = io("http://localhost:3001"); // Replace with your server's host

        // Listen for the "weighing-bridge-data" event
        socket.on("weighing-bridge-data", (receivedData) => {
         //console.log('<<< weighing-bridge-data', receivedData,tareWeight);
          setTareWeight(parseInt(receivedData.data));
        },[]);

        // Clean up the socket connection when the component unmounts
        return () => {
          socket.disconnect();
        };
      }
  }, [tareWeight]);

  const onSubmit = async (event)=>{
    event.preventDefault();

   //console.log('asdf');

    if(tareWeight){
       const response = await axios.patch(`https://weighingbridge.vrajpath.com/vehicles/${weighingData.vehicleId}`,{
        tareWeight: tareWeight
      });

     //console.log('response', response.data);
        updatedWeighingData.tareWeight = tareWeight;
        updatedWeighingData.netWeight = weighingData.grossWeight - tareWeight;
        const res = await axios.post(`https://weighingbridge.vrajpath.com/generate-weighing-receipt`,updatedWeighingData);
        const link = document.createElement('a');
        link.href = res.data.receiptUrl;
        link.target = '__blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Receipt Generated!");
        onRequestClose();
    }
}

  const fields = [
    {
      name: "slipNumber",
      label: "Slip Number",
      type: "input",
      required: true,
      defaultValue:weighingData.slipNumber,
      readOnly:true
    },
    {
      name: "vehicleNumber",
      label: "Vehicle Number",
      type: "input",
      required: true,
      defaultValue:weighingData.vehicleNumber,
      readOnly:true
    },
    {
      name: "vehicleType",
      label: "Vehicle Type",
      type: "input",
      required: true,
      defaultValue:weighingData.vehicleType,
      readOnly:true
    },
    {
      name: "material",
      label: "Material",
      type: "input",
      required: true,
      defaultValue:weighingData.material,
      readOnly:true
    },
    {
      name: "tareWeight",
      label: "Tare Weight",
      type: "input",
      required: true,
      value:tareWeight,
      readOnly:true
    },
  ];

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50">
      <div className="relative w-lg mx-auto my-6">
        <div className="modal bg-white shadow-md rounded-lg w-[30rem]">
          <div className="modal-content p-4">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={onRequestClose}
            >
              <CloseIcon />
            </button>
            <h1 className="text-h1 p-3 mb-4" style={{fontSize: '1.2rem'}}>Generate Receipt</h1>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex flex-row items-center justify-center">
                <div>
                  <h2 className="mb-2">From</h2>
                  <DatePicker
                  value={formDataObject.fromDate}
                  onChange={(date) => setFormDataObject({ ...formDataObject, fromDate: date })}
                  />
                </div>
                <HorizontalRuleIcon className="mx-4 mt-5" /> 
                <div>
                  <h2 className="mb-2">To</h2>
                  <DatePicker
                  value={formDataObject.toDate}
                  onChange={(date) => setFormDataObject({ ...formDataObject, toDate: date })}
                />
                </div>
              </div>
            </LocalizationProvider> */}
            {!updatedWeighingData.tareWeight && <div className="bg-slate-200 flex flex-row items-center justify-center px-16 py-4">
                <p>Weight:</p>
                <p className="text-[#6759FF] ml-2">{(tareWeight) ? `${tareWeight} KG` : 'N/A'} </p>
              </div>}
            <ReusableForm
              fields={fields}
              errors={errors}
              onSubmit={onSubmit}
              customInputClass={customInputClass}
              submitButtonLabel={"Confirm"}
              customClass={customClass}
              customSelectClass={customSelectClass}
              customButtonClass={customButtonClass}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default RequestReportModal;
