import React from "react";
import ReusableForm from "../Components/ReusableForm";
import { useForm } from "react-hook-form";
import Headers from "../Components/Headers";
import NavigationDrawer from "../Components/NavigationDrawer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeightInfo } from "../redux_store/slice/weightInfoSlice";
import { WeightInfoData } from "../redux_store/slice/weightInfoSlice";
import { io } from "socket.io-client";
import toast from "react-hot-toast";
import JsmpegPlayer from "../Components/Jsmpeg";
import Alert from '@mui/material/Alert';

const WeighingInformation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const storedUserData = JSON.parse(localStorage.getItem("userIdData"));

  const [grossWeight, setGrossWeight] = React.useState(0);
  const [netWeight, setNetWeight] = React.useState(0);
  const [vehicleNumber, setVehicleNumber] = React.useState("");
  const [vehicleId, setVehicleId] = React.useState("");
  const [supplierList, setSupplierList] = React.useState([]);
  const [receiverList, setReceiverList] = React.useState([]);
  const [materialList, setMaterialList] = React.useState([]);
  const [vehicleType, setVehicleType] = React.useState("LMV");
  const [rfidNumber, setRfidNumber] = React.useState("");
  const [nonReceiptAlert, setNonReceiptAlert] = React.useState(false);
  const [tareWeight, setTareWeight] = React.useState(0);
  const [slipNumber, setSlipNumber] = React.useState(`${localStorage.getItem('slipNumber').split('-')[0]}-${parseInt(localStorage.getItem('slipNumber').split('-')[1])+1}`);
  
  React.useEffect(() => {
    // Connect to the Socket.io server
    const socket = io("http://localhost:3001"); // Replace with your server's host

    // Listen for the "weighing-bridge-data" event
    socket.on("weighing-bridge-data", (receivedData) => {
     //console.log('<<< weighing-bridge-data', receivedData,tareWeight);
      setGrossWeight(receivedData.data);
      if(tareWeight){
        setNetWeight(receivedData.data-tareWeight);
      }
    },[]);

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [tareWeight]); // The e

  //console.log(grossWeight);

  React.useEffect(() => {
    dispatch(
      fetchWeightInfo({
        userId: storedUserData?.data?.userData?.userId,
        employeeType: storedUserData?.data?.userData?.employeeType,
      })
    );
    
    axios.get(`https://weighingbridge.vrajpath.com/suppliers`).then((response)=>{
      setSupplierList(response.data.map((item)=>item.name));
    });

    axios.get(`https://weighingbridge.vrajpath.com/receivers`).then((response)=>{
      setReceiverList(response.data.map((item)=>item.name));
    });

    axios.get(`https://weighingbridge.vrajpath.com/materials`).then((response)=>{
      setMaterialList(response.data.map((item)=>item.name));
    });
    //console.log("called2");
  }, [slipNumber,grossWeight, tareWeight,vehicleType,rfidNumber,nonReceiptAlert]);

  const weightInfo = useSelector(WeightInfoData);
  const zoneInfo = JSON.parse(localStorage.getItem('userIdData'))?.data?.userData?.zone;
  //console.log(weightInfo);

  //console.log(weightInfo?.zone?.zoneId);

  const [weighingDate, setWeighingDate] = React.useState(new Date().toISOString());

  const onRFIDChange = async (newRfidNumber) => {
    //console.log(newRfidNumber);
    // Get the value entered in the vehicleId field

    //console.log(value);
    // Call the API to fetch tareWeight based on the entered vehicleId
    try {
      const response = await axios.get(`https://weighingbridge.vrajpath.com/vehicles`,{
        params: {
          filter: JSON.stringify({
            where: {
              rfidNumber:newRfidNumber
            }
          })
        }
      });

      if (response.status === 200) {
        // If the response is successful, set the tareWeight state
        const apiData = response.data;
        if(apiData.length){
          setTareWeight(apiData[0].tareWeight);
          if(!apiData[0].tareWeight){
            setNonReceiptAlert(true);
          }
          setVehicleType(apiData[0].vehicleType);
          setVehicleNumber(apiData[0].vehicleNumber);
          setVehicleId(apiData[0].vehicleId);
          toast.success(`Vehicle Found - ${apiData[0].vehicleNumber}`);
        }else{
          setTareWeight(0);
          setNetWeight(0);
          setVehicleType('LMV');
          setNonReceiptAlert();
          setVehicleNumber('');
          setVehicleNumber('');
          toast.error("Vehicle Not Found.");
        }
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };
  //console.log(tareWeight);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const formDataObject = {};
    formData.forEach((value, key) => {
      if (
        key === "charges" ||
        key === "grossWeight" ||
        key === "tareWeight" ||
        key === "netWeight"
      ) {
        formDataObject[key] = parseFloat(value);
      } else {
        formDataObject[key] = value;
      }
    });

    const newFormDataObject = {
      ...formDataObject,
      userId: weightInfo[0]?.userId,
      zoneId: weightInfo[0]?.zone?.zoneId,
      zoneName: weightInfo[0]?.zone?.name,
      employeeName: weightInfo[0]?.fullName,
      city: weightInfo[0]?.zone.city,
      state: weightInfo[0]?.zone.state,
      address: weightInfo[0]?.zone.address,
      rfidNumber:rfidNumber,
      vehicleType:vehicleType,
      vehicleId:vehicleId
    };

    //console.log(newFormDataObject);
    try {
      const res = await axios.post(
        "https://weighingbridge.vrajpath.com/weighing-data",
        JSON.stringify(newFormDataObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const form = event.target;
      toast.success("Weighing Record is Created!");
      localStorage.setItem('slipNumber',`${slipNumber.split('-')[0]}-${parseInt(slipNumber.split('-')[1])+1}`);
      setSlipNumber(`${slipNumber.split('-')[0]}-${parseInt(slipNumber.split('-')[1])+1}`);
      if(!nonReceiptAlert){
        const link = document.createElement('a');
        link.href = res.data.receiptUrl;
        link.target = '__blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setTareWeight(0);
      setNetWeight(0);
      setRfidNumber('');
      setVehicleNumber('');
      setVehicleNumber('');
      setVehicleType('LMV');
      setNonReceiptAlert(false);
      form.reset();
      //console.log(res.data);
      return;
    } catch (error) {
      toast.error("There is some error encountered. Please contact the system administrator.");
      //console.log(error);
    }
  };

  const scanRFID = async ()=>{
    const res = await axios.get(
      "https://weighingbridge.vrajpath.com/rfid-data",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setRfidNumber(res.data.CardData+'');
    onRFIDChange(res.data.CardData+'');
  }

  const fields = [
    // {
    //   name: "rfidNumber",
    //   label: "RFID Number",
    //   type: "number",
    //   required: true,
    //   maxLength: 20,
    // },
    {
      name: "vehicleNumber",
      label: "Vehicle Number",
      type: "text",
      required: true,
      maxLength: 50,
      value: vehicleNumber,
      readOnly:true,
    },
    {
      name: "slipNumber",
      label: "Slip Number",
      type: "text",
      required: true,
      value:slipNumber,
      readOnly:true,
      maxLength: 20,
    },
    {
      name: "charges",
      label: "Charges",
      type: "number",
      required: true,
      maxLength: 50,
    },
    {
      name: "supplier",
      label: "Supplier",
      type: "select",
      required: true,
      options: supplierList,
    },
    {
      name: "measureType",
      label: "Measure Type",
      type: "select",
      required: true,
      options: ["G", "T"]
    },
    {
      name: "weightType",
      label: "Weight Type",
      type: "select",
      required: true,
      options: ["F1", "F2"]
    },
    {
      name: "grossWeight",
      label: "Gross Weight",
      type: "number",
      required: true,
      maxLength: 20,
      value:grossWeight,
      readOnly: true,
    },
    {
      name: "tareWeight",
      label: "Tare Weight",
      type: "number",
      required: true,
      maxLength: 50,
      value: tareWeight,
      readOnly: true,
    },
    {
      name: "netWeight",
      label: "Net Weight",
      type: "number",
      required: true,
      maxLength: 20,
      value:netWeight,
      readOnly: true,
    },
    {
      name: "vehicleType",
      label: "Vehicle Type",
      type: "select",
      required: true,
      options: ["LMV", "HMV"],
      value:vehicleType,
      readOnly: true,
    },
     {
      name: "material",
      label: "Material",
      type: "select",
      required: true,
      options: materialList
    },
    {
      name: "receiver",
      label: "Receiver",
      type: "select",
      required: true,
      options: receiverList,
    },
    {
      name: "weighingDate",
      label: "Weighing Date",
      type: "date",
      required: true,
      defaultValue: new Date().toISOString().split('T')[0],
      readOnly: true
    },
    // {
    //   name: "vehicleId",
    //   label: "Vehicle ID",
    //   type: "text",
    //   required: true,
    //   maxLength: 50,
    //   onChange: onVehicleIdChange,
    // },
  ];

  const customClass = "grid w-2/3 grid-cols-2 gap-4 mx-5 ";
  const customInputClass = "mx-14";
  const customLabelClass = "mx-14";
  const customSelectClass = "mx-14";
  const customButtonClass = "px-16 my-5";
  return (
      <div className="flex flex-row">
        <NavigationDrawer />
        <div className="bg-[#FAFAFA] w-full h-full min-h-screen flex flex-col">
          <Headers
            header="Weighing Information"
            showBackButton={true}
            Linkbackto="/WeighingTracker"
          />
          {
            nonReceiptAlert && <div className="w-2/5 ml-12 pl-4"><Alert severity="warning" style={{fontSize:'14px'}}>Tare Weight is not found. The Record will be saved but the receipt won't be generated.</Alert></div>
          }
          <main className="flex flex-row justify-normal ">
            <ReusableForm
              onSubmit={onSubmit}
              className="flex-row"
              fields={fields}
              errors={errors}
              submitButtonLabel={"Submit"}
              customClass={customClass}
              customInputClass={customInputClass}
              customLabelClass={customLabelClass}
              customButtonClass={customButtonClass}
              customSelectClass={customSelectClass}
              cancelLink={"/WeighingTracker"}
              showCancel={true}
            />
            <div className="bg-white shadow-lg rounded-md p-6 h-1/2 text-center items-center w-[32rem] mr-6 ml-4 mt-20">
              <button className=" text-white bg-[#6759FF] hover:bg-[#5549CC] py-2 px-24 w-full" onClick={scanRFID}>
                Scan RFID Card
              </button>
              <div className=" my-3 rounded-md px-16 py-6 text-[#6759FF] border border-[#6759FF] hover:bg-gray-200">
                SCANNED VEHICLE RFID
                <p className="text-black">{rfidNumber || 'N/A'}</p>
              </div>
              <div className="bg-slate-200 flex flex-row items-center justify-center px-16 py-4">
                <p>Weight:</p>
                <p className="text-[#6759FF] ml-2">{(grossWeight) ? `${grossWeight} KG` : 'N/A'} </p>
              </div>
              <div className="w-full my-3">
                {/* <img src={RFIDtruck} className="my-3 w-full" /> */}
                <JsmpegPlayer
                  wrapperClassName="video-wrapper"
                  videoUrl="ws://localhost:3002"
                />
              </div>
            </div>
          </main>
        </div>
      </div>
  );
};

export default WeighingInformation;
